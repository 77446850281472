import React, { Component, useCallback, useEffect, useState } from 'react'
import './OnboardCard.sass'

import {
  BsEmojiSunglasses,
  BsSun,
  BsPeople,
  BsPersonBoundingBox,
  BsEmojiWink
} from 'react-icons/bs'

interface Props {
  onResponse: (response: any) => void
}

declare global {
  interface Window {
    constraints: any
    stream: any
  }
}

const constraints = (window.constraints = {
  video: true
})

function FaceInstructions({ onResponse }: Props) {
  const [photoState, setphotoState] = useState<string>('')

  const [userHasCamera, setUserHasCamera] = useState(true)

  const handleResponse = useCallback((message: string) => {
    setphotoState(message)
  }, [])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search) // id=123
    if (params.get('fixBrowser')) {
      setphotoState('mobile')
    }
  }, [])

  const handleSuccess = (stream: any) => {
    setUserHasCamera(true)
    const videoTracks = stream.getVideoTracks()
  }

  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target) {
      setPrivacyPolicyAccepted(event.target.checked);
    }
  };

  return (
    <>
      <div className={`${photoState} card`}>
        {userHasCamera ? (
          <div className="have-camera">
            <h1>
              Algumas <span>dicas</span>
            </h1>
            <ul className="instruction-list list-group">
              <li className="list-group-item">
                <i>
                  <BsSun />
                </i>
                Encontre um local iluminado e com fundo neutro, como uma parede
                branca
              </li>
              <li className="list-group-item">
                <i>
                  <BsPersonBoundingBox />
                </i>
                Encaixe a imagem do rosto na moldura verde
              </li>
              <li className="list-group-item">
                <i>
                  <BsPeople />
                </i>
                Certifique-se de que não há outra pessoa no campo de captura
              </li>
              <li className="list-group-item">
                <i>
                  <BsEmojiWink />
                </i>
                Não sorria e não pisque os olhos
              </li>
              <li className="list-group-item">
                <i>
                  <BsEmojiSunglasses />
                </i>
                Não utilize óculos, máscaras ou bonés.
              </li>
            </ul>

            <div className="privacyPolicyCheckbox">
              <input
                type="checkbox"
                className="form-check-input form-control-sm"
                name="valid"
                id="valid"
                onChange={handleCheckboxChange}
              />
              <label className="form-check-label" htmlFor="valid">
                Li e aceito as condições do{' '}
                <a href="https://storage.googleapis.com/terms_of_use_bepass/aviso-de-privacidade-flamengo-bepass.pdf" rel="noreferrer" target="_blank">
                  termo de uso e aviso de privacidade
                </a>{' '}
                para o uso BePass
              </label>
            </div>

            <div className="form-group form-submit">
              <button
                className="btn btn-primary form-control form-control-lg"
                onClick={() => onResponse({ step: 'faceproceed' })}
                disabled={!privacyPolicyAccepted}
              >
                Vamos lá!
              </button>
            </div>
          </div>
        ) : (
          <div className="no-camera">
            {' '}
            <h1>
              Não achamos <span>sua câmera</span>
            </h1>
            <p>
              Não conseguimos acessar sua câmera para prosseguir. <br />
              Por favor, revise as permissões do seu aplicativo ou tente com
              outro celular
            </p>
            <div className="form-group form-submit">
              <a
                className="btn btn-primary form-control form-control-lg"
                onClick={() => window.location.reload()}
              >
                Recarregar
              </a>
            </div>
          </div>
        )}
      </div>
      <ul className="step-counter">
        <li className="active"></li>
        <li className="active"></li>
        <li className="active"></li>
        <li></li>
      </ul>
    </>
  )
}

export default FaceInstructions
